<template>
  <DefaultLayout>
    <Spinner v-if="loading" />
    <template v-if="!loading && order">
      <div v-if="pending" class="content bg-white min-h-full shadow">
        <div class="p-4 md:p-12">
          <h1 class="mb-4" v-text="order.subject" />
          <h3 class="mb-4">{{$t('collect.greeting',{name: order.name})}}</h3>
          <p v-text="order.message" class="mb-8 whitespace-pre-line" />

          <h2 class="mb-4">{{$t('collect.order-info')}}</h2>
          <Info class="-m-2 mb-8">
            <InfoItem :label="$t('collect.customer')" :value="`${order.name}`" />
            <InfoItem :label="$t('collect.order_number')" :value="order.order_number" />
            <InfoItem v-if="order.order_line" :label="$t('collect.order_line')" :value="order.order_line" />
          </Info>

          <h2 class="mb-4">{{$t('collect.difference')}}</h2>

          <!-- <table class="w-full md:w-1/2 mb-8">
            <template v-for="diff in diffs">
              <tr v-if="order[diff]" :key="diff">
                <td class="text-xs text-gray-600 font-semibold">{{$t(`collect.${diff}`)}}</td>
                <td class="pl-4 font-medium text-right">{{formatCurrency(order[diff])}}</td>
              </tr>
            </template>
            <tr class="border-t-2">
              <td class="text-xs text-gray-600 font-semibold">{{$t('collect.amount')}}</td>
              <td class="pl-4 font-semibold text-right">{{formatCurrency(order.amount)}}</td>
            </tr>
          </table> -->

          <p class="mb-4" v-text="$t('collect.diff-information')" />

          <table class="product-table w-full table-fixed mb-8">
            <!-- <thead>
              <tr class="text-sm font-semibold">
                <th class="w-10"></th>
                <th class="text-left w-4/5">Product</th>
                <td nowrap class="text-right">{{$t(`collect.difference`)}}</td>
              </tr>
            </thead> -->
            <tbody>
              <tr v-for="line in order.order_lines" :key="line.id">
                <td class="py-2 w-10">
                  <!-- <input type="checkbox" v-model="line.selected"> -->
                  <Checkbox v-model="line.selected" />
                </td>
                <td class="w-4/5 ellipsis font-medium text-xl py-2">
                  <a v-if="line.link" :title="line.name" :href="line.link" target="_blank" class="text-blue-700">{{line.name}}</a>
                  <span v-else :title="line.name" class="ellipsis">{{line.name}}</span>
                  <span class="text-gray-500 text-lg"> &times; {{line.quantity}}</span>
                </td>
                <td class="py-2 pl-4 font-medium text-right text-xl" :class="{'text-gray-400': !line.selected}">{{formatCurrency(line.amount)}}</td>
              </tr>
            </tbody>
          </table>

          <hr class="border-t-2 mb-4">
          
          <Button @click="pay" :disabled="checkingOut || !selectedTotal" class="sticky-fluff bg-green-500 text-white md:float-right w-full md:w-auto mb-4 sticky bottom-1">
            <PaymentIcon class="inline-block mb-1" :class="{'animate-spin': checkingOut}" />
            {{$t('collect.action.pay',{amount: formatCurrency(selectedTotal)})}}
          </Button>
          <Button @click="showCancelModal = true" :disabled="checkingOut" class="border border-red-600 hover:bg-red-600 text-red-600 hover:text-white w-full md:w-auto mb-4">
            {{$t('collect.action.cancel-order')}}
          </Button>

          <Modal v-if="showCancelModal" width="128" @close="showCancelModal = false">
            <template #header>{{$t('collect.action.cancel-order')}}</template>
            <template #body>{{$t('collect.action.cancel-order-confirmation')}}</template>
            <template #footer>
              <Button @click="showCancelModal = false" :disabled="cancelling" class="border bg-brand text-white w-full sm:w-auto mb-4 sm:mb-0">
                {{$t('common.go-back')}}
              </Button>
              <Button @click="cancelOrder" :disabled="cancelling" class="border bg-red-600 text-white w-full sm:w-auto float-right">
                {{$t('collect.action.cancel-order')}}
              </Button>
            </template>
          </Modal>

        </div>
      </div>
      <FloatingBox v-else class="sm:w-128">
        <div class="flex items-center flex-col sm:flex-row">
          <component 
            :is="issue ? ExclamationTriangle : CheckCircle"
            :size="56" 
            class="rounded-full p-1 w-14 flex-shrink-0 mr-6 mb-6" 
            :class="{
              'text-white bg-red-400': issue,
              'text-white bg-green-400': !issue,
            }"
          />
          <div>
            <h1 class="text-lg font-bold mb-4">{{$t(`collect.${reason}.title`)}}</h1>
            <p>{{$t(`collect.${reason}.description`)}}</p>
            <br>
          </div>
        </div>
        <Button anchor href="https://aeropost.com" class="bg-brand text-white w-full ">
          {{$t('collect.action.aeropost')}}
        </Button>
      </FloatingBox>
    </template>
  </DefaultLayout>
</template>

<script>
import api from '../api';
import DefaultLayout from '../layouts/Default.vue'
import Info from '../components/Info.vue'
import InfoItem from '../components/InfoItem.vue'
import Button from '../components/Button.vue'
import Spinner from '../components/Spinner.vue'
import Modal from '../components/Modal.vue'
import PaymentIcon from '../icons/Payment.vue'
import ExclamationTriangle from '../icons/ExclamationTriangle.vue';
import CheckCircle from '../icons/CheckCircle.vue';
import FloatingBox from '../components/FloatingBox'
import Checkbox from '../components/Checkbox.vue';

const STATUS_CUSTOMER_CANCELLED = -100;
const STATUS_EXPIRED            = -50;
const STATUS_AWAITING_RESPONSE  = 20;
const STATUS_CUSTOMER_PAID      = 80;

export default {
  name: 'Collect',
  components: {
    DefaultLayout,
    FloatingBox,
    Info,
    InfoItem,
    Button,
    Spinner,
    PaymentIcon,
    Modal,
    Checkbox,
  },
  props: {
    hash: String
  },
  data() {
    return {
      ExclamationTriangle,
      CheckCircle,
      loading: true,
      order: null,
      checkingOut: false,
      cancelling: false,
      showCancelModal: false,
      diffs: [
        'product_diff',
        'shipping_diff',
        'duties_diff',
        // 'amount',
      ]
    }
  },
  async created() {
    await this.fetch()
  },
  computed: {
    pending() { return this.order.status == STATUS_AWAITING_RESPONSE },
    reason() {
      switch(this.order.status) {
        case STATUS_EXPIRED: return 'expired'
        case STATUS_CUSTOMER_PAID: return 'already-paid'
        case STATUS_CUSTOMER_CANCELLED: return 'already-cancelled'
        default: return 'error'
      }
    },
    issue()  {
      return !~['already-paid','already-cancelled'].indexOf(this.reason)
    },
    selectedTotal() {
      return this.order.order_lines.filter(l => l.selected).reduce((a,b)=>a+b.amount, 0)
    }
  },
  methods: {
    async fetch() {
      this.loading = true

      try {
        const {data: {data}} = await api.get(`payment-request/${this.hash}`)
        this.order = data
        this.$i18n.locale = data.locale

      } finally {
        this.loading = false
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat(this.order.locale, { 
        style: 'currency', 
        currency: this.order.currency, 
      }).format(value/100)
    },
    async cancelOrder() {
      this.cancelling =  true
      await api.get(`payment-request/${this.hash}/cancel`)
      this.$router.push({name: 'collect-cancelled', params: {hash: this.hash}})
    },
    async pay() {
      this.checkingOut = true
      try {
        const selectedLines = this.order.order_lines.filter(l => l.selected).map(l => l.id)
        const {data: url} = await api.post(`payment-request/${this.hash}/checkout`,selectedLines)
        window.location.href = url
        console.log(url)
      } catch(e) {
        console.error(e)
      } finally {
        this.checkingOut = false
      }
    }
  },
  // metaInfo() {
  //   return {
  //     title: this.$t('home.title'),
  //   }
  // } 
};
</script>