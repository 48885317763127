<template>
  <DefaultLayout>
    <FloatingBox class="sm:w-128">
      <div class="flex items-center flex-col sm:flex-row">
        <check-circle :size="56" class="rounded-full p-1 w-14 flex-shrink-0 text-white bg-green-400 mr-6 mb-6" />
        <div>
          <h1 class="text-lg font-bold mb-4">{{$t('collect.success.title')}}</h1>
          <p>{{$t('collect.success.description')}}</p>
          <br>
        </div>
      </div>
      <Button anchor href="https://aeropost.com" class="bg-brand text-white w-full ">
        {{$t('collect.action.aeropost')}}
      </Button>
    </FloatingBox>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default.vue'
import FloatingBox from '../components/FloatingBox'
import CheckCircle from '../icons/CheckCircle.vue'
import Button from '../components/Button.vue'

export default {
  name: 'Search',
  components: {
    DefaultLayout,
    FloatingBox,
    CheckCircle,
    Button,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: this.$t('collect.success.title'),
    }
  } 
};
</script>
