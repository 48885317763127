<template>
  <div 
    @click="$emit('input',!value)" 
    class="cursor-pointer text-xl rounded-lg w-7 text-center"
    :class="{
      'bg-red-500 text-white': !value,
      'bg-green-500 text-white': value,
    }"
  >
    <template v-if="value">&#10003;</template>
    <template v-else>&#10007;</template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    }
  },
}
</script>